<template>
  <div v-if="this.$root.dsvisible" class="chat">
    <div class="chatinner">
      <h1>Datenschutz</h1>

      <p>Wir möchten wissen, was dir an der Messe gefällt. Dafür nutzen wir Google Analytics, welches Cookies auf deinem
        Rechner setzt. Genaue Informationen dazu findest du in unserer
        <router-link to="/Datenschutz" style="font-weight: bold">Datenschutzerklärung</router-link>.
      </p>
      <div style="display: flex;">
        <a class="button button-neg" @click="decline" style="flex-grow: 1">Nein, nicht nutzen</a>
        <a class="button" @click="accept" style="flex-grow: 1">Akzeptieren</a>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
h1 {
  margin-top: 0;
}

.chat {
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 99999;
  position: fixed;
  left: 0;
  right: 0;
  height: 100vh;
}

.chatinner {
  max-height: 100vh;
  margin: 0 auto;
  background-color: rgba(255, 255, 255);
  max-width: 340px;
  padding: 20px;
}


.button {
  background-color: #444444;
  color: #fff;
  border-radius: 999px;
  padding: 10px 5px;
  line-height: 20px;
  text-align: center;
  font-weight: bolder;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
}

.button-neg {
  background-color: transparent;
  color: #989898;
  border-radius: 999px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  font-weight: bolder;
  cursor: pointer;
}
</style>

<script>
import {bootstrap} from 'vue-gtag'

export default {
  props: [],
  data() {
    return {
      enabled: false,
    }
  },
  mounted() {
    this.enabled = JSON.parse(localStorage.getItem('InsiderStatsEnabled'));
    if (this.enabled) {
      this.enablePlugin()
      this.$root.dsvisible = false
    }
  },
  methods: {
    enablePlugin() {
      // https://matteo-gabriele.gitbook.io/vue-gtag/custom-installation
      /*
      bootstrap().then(gtag => {
        // all done!

      })
      */
      bootstrap()
    },

    accept: function () {
      this.enablePlugin()
      this.enabled = true;
      localStorage.setItem('InsiderStatsEnabled', JSON.stringify(this.enabled));
      this.$root.dsvisible = false
    },
    decline: function () {
      this.enabled = false;
      localStorage.setItem('InsiderStatsEnabled', JSON.stringify(this.enabled));
      this.$root.dsvisible = false

    },
  },
}
</script>
