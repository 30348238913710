import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/Impressum',
        name: 'Impressum',
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
        path: '/hall-:id/:stand?',
        name: 'Hall',
        props: true,
        component: () => import(/* webpackChunkName: "hall" */ '../views/Hall.vue')
    },

    {
        path: '/Datenschutz',
        name: 'Datenschutz',
        component: () => import(/* webpackChunkName: "ds" */ '../views/Datenschutz.vue')
    },

]

const router = new VueRouter({
    routes,
    scrollBehavior() {
        return {x: 0, y: 0}
    }
})

export default router
