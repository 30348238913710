<template>
  <div id="app">
    <cookie></cookie>
    <div id="nav" v-if="$route.name !== 'Hall'">
      <div id="logocontainer">
        <router-link to="/"><img id="logo" src="./assets/logo/wfe_logo.jpg"></router-link>
      </div>
    </div>
    <div v-if="this.$root.loading">Loading...</div>
    <chat v-if="!this.$root.loading" ref="chatwidget" :chatvisible="this.$root.chatvisible"/>
    <router-view  v-if="!this.$root.loading" id="view"/>
    <Footer v-if="$route.name !== 'Hall'"/>
  </div>
</template>

<style lang="scss">
@font-face {
  font-family: "Museo Slab W01_300";
  src: url("./assets/fonts/905336/23674a3a-6e87-4ade-817d-6bd3b6a7d538.woff2") format("woff2"), url("./assets/fonts/905336/4548ac88-af2c-4b63-ac2b-94e9ef6cd1b9.woff") format("woff");
}

@font-face {
  font-family: "Museo Slab W01_500";
  src: url("./assets/fonts/905342/16bb87bf-d138-429b-bc78-dae6c4f109fb.woff2") format("woff2"), url("./assets/fonts/905342/3cf54835-9705-474e-9afa-46f2d59589e7.woff") format("woff");
}

@font-face {
  font-family: 'Clan Offc Narrow';
  src: url('./assets/fonts/ClanOffc-NarrBook.ttf') format('truetype');
}

* {
  font-family: "Clan Offc Narrow", sans-serif;
  color: #333;
}

a {
  text-decoration: none;
  color: #333;
}

h1, h2, h3 {
  font-family: "Museo Slab W01_300", sans-serif;
}

.joystick {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome, Edge, Opera and Firefox */

}

#logo {
  max-height: 120px;
  margin-top: 10px;
}

body {
  padding: 0;
  margin: 0;
  color: #333;
  /*
  background: linear-gradient(45deg, #3eb4ca, #a0b134, #fa9b00);
  background-attachment: fixed;*/
  background-color: #fff;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #444;
}

#nav {
  padding: 20px;

  right: 0;
  left: 0;
  top: 0;
  z-index: 9999;
  text-align: center;
}

</style>
<script>
import Footer from './components/footer/footer'
import chat from './components/chat'
import Cookie from "@/components/cookie";

export default {
  components: {Cookie, Footer, chat},
}
</script>
