import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueNativeSock from 'vue-native-websocket'

import VueGtag from "vue-gtag"

Vue.use(VueGtag, {
    config: {
      id: "UA-38400341-6",
      params: {
        anonymize_ip: true
      }
    },
    bootstrap: false
  },
  router
)

Vue.use(VueNativeSock, process.env.VUE_APP_WSS_ENDPOINT, {
  reconnection: true, // (Boolean) whether to reconnect automatically (false)
  reconnectionAttempts: 0, // (Number) number of reconnection attempts before giving up (Infinity),
  reconnectionDelay: 3000, // (Number) how long to initially wait before attempting a new (1000)
  format: 'json',
})

Vue.config.ignoredElements = [
  'a-box',
  'a-scene',
  'a-assets',
  // Use a `RegExp` to ignore all elements that start with "ion-"
  // 2.5+ only
  /^a-/,
]
new Vue({
  data: {
    loading: false,
    chatvisible: false,
    show_Marienberg: false,
    show_Stollberg: false,
    show_Aue: false,
    show_Annaberg: false,
    dsvisible: true,
    Einleitung: '',
    teilnehmer: [],
    hallen: [
      {id: 1, name: 'Beratung | Information', mcolor: '#faf192'},
      {id: 2, name: 'Energie / Hotel / Nahrungsmittel / Transport', mcolor: '#ccc1db'},
      {id: 3, name: 'Finanzen / Sicherheit / Verwaltung', mcolor: '#f29695'},
      {id: 4, name: 'Gesundheit / Pflege', mcolor: '#a3ce8e'},
      {id: 5, name: 'Handwerk / Handel', mcolor: '#fabc60'},
      {id: 6, name: 'Industrie I', mcolor: '#8bcef5'},
      {id: 7, name: 'Industrie II', mcolor: '#8bcef5'},/* #cdeafb */
      {id: 71, name: 'Industrie III', mcolor: '#8bcef5'},/* #cdeafb */
      {id: 8, name: 'Studieneinrichtungen / Schulen', mcolor: '#4ac2f0'},

    ],
  },
  mounted() {

    this.loading = true
    axios
      .get(process.env.VUE_APP_API_ENDPOINT + 'firma/apimesse2')
      .then(response => {
        //console.log(response.data);
        this.teilnehmer = response.data.firmen

        this.Einleitung = response.data.Einleitung

        this.show_Annaberg = response.data.show_Annaberg
        this.show_Aue = response.data.show_Aue
        this.show_Marienberg = response.data.show_Marienberg
        this.show_Stollberg = response.data.show_Stollberg
      })
      .catch(error => {
        console.log(error)
        alert(error)
        this.errored = true
      })
      .finally(() => this.loading = false)

  },
  router,
  render: h => h(App),
}).$mount('#app')
