<template>
  <div class="home">

    <div class="ausrichtung">
      <div class="box" style="background-color: transparent">
        <div v-html="this.$root.Einleitung" class="custom"></div>
      </div>
      <div class="col-2">
        <div class="box hallenbox">

          <h2>Hallen:</h2>
          <ul>
            <li v-for="Halle in hallen" v-bind:key="Halle.id" v-bind:style="{backgroundColor: Halle.mcolor}">
              <router-link :to="{ name: 'Hall', params: { id: Halle.id }}">
                {{ Halle.name }}

              </router-link>
            </li>
          </ul>

        </div>
        <div class="box ausstellerbox">
          <h2>Aussteller:</h2>
          <div style="display: flex;align-items: center; flex-wrap: wrap; margin-bottom: 14px">
            <input v-model="ausstellersearch" type="text" class="search" placeholder="Aussteller suchen..."

            >

            <div>
            <span style="margin-left: 10px">Halle: </span>
            <select v-model="hallesearch">
              <option :value="null">
                --Alle--
              </option>
              <option v-for="Halle in hallen" v-bind:key="Halle.id" :value="Halle.id"
                      style="padding: 10px;color: white"
                      v-bind:style="{backgroundColor: Halle.mcolor}">
                {{ Halle.name }}

              </option>
            </select>
            </div>
          </div>
          <div style="display: flex;align-items: center; margin-bottom: 14px; flex-wrap: wrap;">

            <legend v-if="this.$root.show_Marienberg"><input type="checkbox" v-model="showMarienberg">Marienberg
            </legend>
            <legend v-if="this.$root.show_Stollberg"><input type="checkbox" v-model="showStollberg">Stollberg</legend>
            <legend v-if="this.$root.show_Aue"><input type="checkbox" v-model="showAue">Aue-Bad Schlema</legend>
            <legend v-if="this.$root.show_Annaberg"><input type="checkbox" v-model="showAnnaberg">Annaberg-Buchholz
            </legend>
          </div>
          <ul>
            <li v-for="firma in aussteller" :key="firma.id">
              <router-link :to="{ name: 'Hall', params: { id: firma.Halle, stand:firma.id }}">
                <div style="width: 14px;height: 14px;display:inline-block;margin-right: 5px;border-radius: 3px;"
                     :style="{ backgroundColor: hallcolor(firma) }"></div>
                {{ firma.Firma }}
                <div v-if="chatonline(firma)"
                     style="font-size:12px;display:inline-block;padding:2px 5px;background-color: #196c37;color: white;border-radius: 3px">
                  Live Chat
                </div>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import filter from 'lodash/filter'

export default {
  name: 'Home',
  components: {},
  data() {
    return {
      ausstellersearch: '',
      showMarienberg: true,
      showStollberg: true,
      showAue: true,
      showAnnaberg: true,
      publicPath: process.env.BASE_URL,
      hallesearch: null,
    }
  },
  computed: {
    aussteller() {
      let self = this

      return filter(this.$root.teilnehmer, function (tn) {
        return tn.Firma.toLowerCase().includes(self.ausstellersearch.toLowerCase())
          && (
            tn.is_Marienberg === self.showMarienberg
            || tn.is_Stollberg === self.showStollberg
            || tn.is_Aue === self.showAue
            || tn.is_Annaberg === self.showAnnaberg
          )
          && (tn.Halle === self.hallesearch || self.hallesearch == null)
      })
    },
    countAussteller(id) {
      return this.$root.teilnehmer.filter(
        tn => parseInt(tn.Halle) === parseInt(id),
      ).length
    },
    hallen() {
      return this.$root.hallen
    },
  },
  methods: {
    chatonline(firm) {
      let firma = firm

      console.log(this.$parent.$refs.chatwidget.firms)

      let firms = JSON.parse(JSON.stringify(this.$parent.$refs.chatwidget.firms))
      // console.log(firms)
      let valObj = firms.filter(function (elem) {
        return (parseInt(elem.firmaid) === parseInt(firma.id))
      })

      return valObj.length > 0

    },
    hallcolor(firm) {
      let firma = firm

      let hallen = JSON.parse(JSON.stringify(this.$root.hallen))

      let valObj = hallen.filter(function (halle) {

        return (parseInt(halle.id) === parseInt(firma.Halle))
      })
      if (valObj[0] && valObj[0].mcolor)
        return valObj[0].mcolor

    },
  }
}
</script>
<style scoped lang="scss">
.ausrichtung {
  max-width: 1040px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
}

.search {
  border: 1px solid #444;
  background-color: white;
  background-image: url("../assets/INSIDERTREFF_Lupe.svg");
  background-repeat: no-repeat;
  padding: 6px 15px;
  padding-left: 40px;
  background-size: 20px 20px;
  background-position: 10px center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #444;
  max-width: 200px;
  color: #444;
  font-size: 16px;
  line-height: 22px;
  box-sizing: border-box;
  display: inline-block;
  border-radius: 4px;
}

legend {
  display: inline-block;
  flex-shrink: 0;
  margin-right: 10px;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #444;
  background-color: white;
  padding: 6px 15px;
  color: #444;
  font-size: 16px;
  line-height: 22px;
  box-sizing: border-box;
  display: inline-block;
  max-width: 200px;
  border-radius: 4px;
}

@media (max-width: 768px) {
  .box {
    width: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

.col-2 {
  display: flex;
  @media (max-width: 768px) {
    display: block;
  }
}

.home {
  padding-bottom: 60px;
}

.box {
  padding: 20px;
  margin-right: 20px;
  margin-left: 20px;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0;
      margin-bottom: 8px;
      border-radius: 10px;

      &:hover {
        filter: brightness(0.9);
      }

      a {
        font-family: "Museo Slab W01_300", sans-serif;
        display: block;
        color: #333;
      }
    }
  }

  &.hallenbox {
    width: 240px;
    @media (max-width: 768px) {
      width: auto;
    }

    ul {
      li {
        transform: rotate(-3deg);

        a {
          padding: 10px;
          color: #000;
        }
      }
    }
  }

  h2 {
    margin-top: 0;
  }
}

a {
  text-decoration: none;
}

a.hinweisbox {
  margin: 0;
  margin-bottom: 8px;
  border-radius: 10px;
  background-color: #3300ff;
  padding: 10px;

  &:hover {
    filter: brightness(0.9);
  }

  font-family: "Museo Slab W01_300", sans-serif;
  display: inline-block;
  color: #fff !important;
}
</style>
