<template>
  <div id="footerrahmen" class="noprint">
    <div id="footer" class="ausrichtung-inner">
      <div class="logo-footer">
        <a href="/"  ><img src="../../assets/logo/logo-hiergeblieben1.jpg" ></a>
      </div>

      <div class="footer-nav">
        <div class="navgrid">
          <router-link to="/">Startseite</router-link>

          <router-link to="/Datenschutz">Datenschutz</router-link>

          <router-link to="/Impressum">Impressum</router-link>
        </div>

        <div style="clear: both" />
      </div>

     <br>

      <div class="bottom" >
        &copy; Wirtschaftsförderung Erzgebirge GmbH.
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
/* COLOR SETTINGS */
$color-main: rgba(39, 59, 110, 1);
$color-main2: rgba(126, 126, 126, 1);
$color-main3: rgba(199, 199, 200, 1);

$color-second: rgb(25, 108, 55);
$color-second-80: rgba(0, 152, 145, 0.8);
$color-second2: rgba(127, 94, 129, 1);
$color-second3: rgba(217, 179, 197, 1);

$color-third: rgba(233, 76, 102, 1);
$color-third2: rgba(242, 147, 152, 1);
$color-third3: rgba(250, 209, 208, 1);

$color-fourth: #333333;
$color-inverted: white;

$color-blau: #53b4c9;
$color-orange: #fa9b00;
$color-orange-80: rgba(250, 155, 0, 0.8);

$color-grau-dunkel: #333;
$color-gruen-hell: #a4bd46;


/* ergebirge farben */
$color-lila: rgb(51, 0, 255);
$color-gruen-dunkel: rgb(115, 255, 0);

/* PAGE SETTINGS */
$max-width: 900px;
$max-width-inner: 768px;
$ausrichtung: $max-width;
$ausrichtung-inner: $max-width-inner;

$display-1024: 1024px;
$display-900: 900px;
$display-768: 768px;
$display-600: 600px;
$display-480: 480px;
$display-360: 360px;
$display-mobile-breakpoint: $display-900;
$display-mobile-breakpoint-alternative: $display-480;

$abstand-smaller: 10px;
$abstand-small: 20px;
$abstand-normal: 40px;
$abstand-big: 80px;


$font-family-h1: "Museo Slab W01_300", sans-serif;
$h1-font-size: 40px;
$h1-line-height: 48px;
$h1-color: $color-grau-dunkel;

$font-family-h2: "Museo Slab W01_300", sans-serif;
$h2-font-size: 25px;
$h2-line-height: 35px;
$h2-color: $color-grau-dunkel;

$font-family-h3: "Museo Slab W01_300", sans-serif;
$h3-font-size: 22px;
$h3-line-height: 30px;
$h3-color: $color-gruen-dunkel;

$font-family-text: "Clan Offc Narrow", sans-serif;
$text-font-size: 16px;
$text-line-height: 28px;
$text-color: $color-grau-dunkel;

$font-family-link: "Clan Offc Narrow", sans-serif;
$link-font-size: 16px;
$link-line-height: 28px;
$link-color: $color-gruen-dunkel;

#footerrahmen {
  background-color: $color-inverted;
  margin: 0 auto;
  width: 100%;

  img {
    max-width: 100%;
  }

  #footer {
    text-align: center;
    padding-bottom: 40px;
    max-width: 700px;
    margin: 0 auto;

    @media screen and (max-width: $display-mobile-breakpoint) {
      padding-bottom: 30px;
    }
    @media screen and (max-width: $display-768) {
      padding-bottom: 20px;
    }
    @media screen and (max-width: $display-480) {
      padding-bottom: 10px;
    }

    .logo-footer {
      margin: 0 auto;
      width: 175px;

      @media screen and (max-width: $display-mobile-breakpoint) {
        max-width: 200px;
      }
      @media screen and (max-width: $display-768) {
        max-width: 170px;
      }
      @media screen and (max-width: $display-480) {
        max-width: 140px;
      }

      img {

      }
    }

    div.navgrid a {
      margin-left: 5px;
      margin-right: 5px;
    }

    .firmen-logos {
      margin-top: 40px;
      margin-bottom: 20px;

      img {
        height: 14px;
        padding-left: 15px;
        padding-right: 15px;
        border-right: 1px solid $color-grau-dunkel;
        box-sizing: border-box;
      }

      a {
        &:last-child {
          img {
            border: 0;
          }
        }
      }
    }

    .bottom {
      color: $color-grau-dunkel;
      font-weight: bold;
      font-size: 14px;

      @media screen and (max-width: $display-480) {
        font-size: 12px;
      }
    }
  }
}


</style>
